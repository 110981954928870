<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Company</label>
            <b-select
              v-model="filter.company"
              :options="filterCompanies"
              @change="onFilterChange"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
        >
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onShowNewForm"
          >
            New Position
          </b-button>
        </b-col>

      </b-row>
      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="onShowEditForm(row.item)"
          >
            Edit
          </b-button>
        </template>

      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-position-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="company"
            vid="company"
            rules="required"
          >
            <b-form-group>
              <label for="company">Company</label>
              <b-form-select
                id="company"
                v-model="position.company"
                :options="list.companies"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Company --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position code"
            vid="position_code"
            rules="required|max:13"
          >
            <div class="form-group">
              <label for="position_code">Code</label>
              <b-input
                id="position_code"
                v-model="position.position_code"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter position code"
                autocomplete="off"
                maxlength="13"
                :disabled="state.busy || state.editing"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="position name"
            vid="position_name"
            rules="required|max:150"
          >
            <div class="form-group">
              <label for="position_name">Name</label>
              <b-input
                id="position_name"
                v-model="position.position_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter position name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="position.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminPositionService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminPositions',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Positions'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        companies: []
      },
      filter: {
        company: 'All'
      },
      position: {
        id: 0,
        position_code: '',
        position_name: '',
        company: null,
        active: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'position_code', sortable: true },
          { key: 'position_name', sortable: true },
          { key: 'company_name', sortKey: 'companies.company_name', label: 'company', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Position' : 'New Position'
    },

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    }
  },

  mounted () {
    core.index()
    this.fetchList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminPositionService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_company=${this.filter.company}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async fetchList () {
      await SharedListService.getCompanies().then(({ data }) => {
        this.list.companies = data.map(({ id, company_name }) => ({
          text: company_name,
          value: id
        }))
      })
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    onShowNewForm () {
      this.state.editing = false
      this.position.position_code = ''
      this.position.position_name = ''
      this.position.company = null
      this.position.active = 1
      this.$bvModal.show('modal-position-form')
    },

    onShowEditForm (item) {
      this.state.editing = true
      this.position.id = item.id
      this.position.position_code = item.position_code
      this.position.position_name = item.position_name
      this.position.company = item.company_id
      this.position.active = item.active
      this.$bvModal.show('modal-position-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create position?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminPositionService.post(this.position).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-position-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminPositionService.put(this.position).then(({ data }) => {
          this.$bvModal.hide('modal-position-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.position.id })
            row.position_code = data.position.position_code
            row.position_name = data.position.position_name
            row.company_id = data.position.company_id
            row.company_name = data.position.company.company_name
            row.active = data.position.active
            row.updated_at = data.position.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
